import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Divider,
    Flex,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Show,
    Text,
    useMediaQuery,
    VStack,
} from '@chakra-ui/react'
import { Localization, type PostsAccordionAreasData } from '@core/app/types/globalApiType'
import { type Posts, PostsFilterItems } from '@core/app/types/postsApiType'
import { IconSearch } from '@core/assets/icons/search'
import { IconUndo } from '@core/assets/icons/undo'
import { AppButton } from '@core/components/AppButton'
import { CustomFullScreenModal } from '@core/components/Modal/ModalFullScreen'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { Form, Formik } from 'formik'
import { CheckboxItem } from '@page/posts/components/Checkbox/Checkbox'
import AreasAccordion from '@page/posts/components/Sidebar/AreasAccordion'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface Props {
    postsData: Posts | undefined
    onSelect: (
        id: number | string,
        type: 'country' | 'region' | 'city' | 'industry' | 'jobType' | 'workType' | 'office',
        value: boolean
    ) => void
    reset: () => void
    isOpened: boolean
    closeModal: () => void
    searchQuery: string
    setSearchQuery: (value: string) => void
    isLoading?: boolean
    areasData: PostsAccordionAreasData[]
    countryParams: number[]
    regionParams: number[]
    cityParams: number[]
    industryParams: number[]
    jobTypeParams: number[]
    workTypeParams: number[]
    officeParams: number[]
    sortValue?: 'newest' | 'oldest'
}

function PostSideBarNew({
    postsData,
    onSelect,
    reset,
    isOpened,
    closeModal,
    searchQuery,
    setSearchQuery,
    isLoading,
    areasData,
    countryParams,
    regionParams,
    cityParams,
    industryParams,
    jobTypeParams,
    workTypeParams,
    officeParams,
    sortValue,
}: Props) {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { appTheme } = useAppTheme()
    const [isDesktop] = useMediaQuery('(min-width: 1230px)', {
        fallback: false,
    })
    const [localization, setLocalization] = useState<Localization>({})
    // const {
    //     newSettings: {
    //         general: { BackgroundText },
    //     },
    // } = appTheme || { newSettings: { general: { BackgroundText: null } } }

    const BackgroundText = appTheme?.newSettings?.general?.BackgroundText

    const meta = postsData?.data?.metadata
    const filters = postsData?.data?.filters
    const settings = postsData?.data?.filtersSettings
    // const activeFilters = localStorage.getItem('jobs_search_string')
    const activeFilters =
        countryParams.length > 0 ||
        regionParams.length > 0 ||
        cityParams.length > 0 ||
        industryParams.length > 0 ||
        jobTypeParams.length > 0 ||
        workTypeParams.length > 0 ||
        officeParams.length > 0 ||
        sortValue === 'oldest' ||
        searchQuery.length > 0

    useEffect(() => {
        if (!appTheme) return
        if (appTheme && appTheme?.localization) {
            setLocalization(appTheme?.localization)
        }
    }, [appTheme])

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>, submitForm: () => void) => {
        setSearchQuery(e.target.value)
        submitForm()
    }

    const isSingleCountry = areasData.length === 1
    const isSingleEmptyCountry = areasData.length === 1 && areasData[0].regions.length === 0

    return (
        <CustomFullScreenModal
            isHeaderVisible={false}
            isFooterVisible={false}
            isOpen={isOpened}
            onClose={() => closeModal()}
            isFullScreen={true}
            isDesktop={isDesktop}
            isSideBar={true}
            toBottom
        >
            <Box
                w={{ base: '100%', xl: '384px' }}
                // removed to be a part of page
                // position={{ xl: 'sticky' }}
                top="120px"
            >
                {isDesktop && (
                    <Text
                        mb={5}
                        fontSize={20}
                        fontWeight={600}
                        textTransform="capitalize"
                        height="40px"
                        color={settings?.boxStyle ? 'text.dark' : `#${BackgroundText ? BackgroundText : '000000'}`}
                    >
                        {localization[12] ? localization[12] : t('277')}
                    </Text>
                )}
                <Box
                    w={'100%'}
                    bg={settings?.boxStyle ? 'bg.1' : '#fff'}
                    borderRadius="8px"
                    // removed to be a part of page
                    // overflow="hidden"
                    position="relative"
                    // pb={{ base: 12, xl: 0 }}
                    boxShadow={'0px 1px 6px 0px rgba(0,0,0,0.15)'}
                    // removed to be a part of page
                    // maxHeight="80vh"
                    pb={{ base: '50px', xl: '0px' }}
                    overflowY="auto"
                    sx={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        '-ms-overflow-style': 'none', // Internet Explorer 10+
                        'scrollbar-width': 'none', // Firefox
                    }}
                >
                    <Flex justifyContent="space-between" alignItems="center" p={{ base: 4, xl: 6 }}>
                        <Text
                            fontSize={{ base: 'sm', xl: 'md' }}
                            fontWeight={{ base: 'medium', xl: 'semibold' }}
                            color={settings?.boxStyle ? 'text.dark' : `#${BackgroundText ? BackgroundText : '000000'}`}
                        >
                            {t('6448')}
                        </Text>
                        <Button
                            h="28px"
                            p="4px 8px"
                            borderRadius="24px"
                            variant="default"
                            backgroundColor="transparent"
                            aria-label={`${t('6475')}`}
                            onClick={() => reset()}
                            isDisabled={!activeFilters}
                            _hover={{
                                backgroundColor: '#F5F5F5',
                                '& svg path': {
                                    fill: '#1A1A1A',
                                },
                                '& p': {
                                    color: '#1A1A1A',
                                },
                            }}
                            _disabled={{
                                backgroundColor: 'transparent',
                                cursor: 'not-allowed',
                                '& svg path': {
                                    fill: '#E0E0E0',
                                },
                                '& p': {
                                    color: '#E0E0E0',
                                },
                            }}
                        >
                            <HStack>
                                <Text
                                    fontSize="sm"
                                    fontWeight="medium"
                                    color={
                                        settings?.boxStyle
                                            ? '#828282'
                                            : `#${BackgroundText ? BackgroundText : '828282'}`
                                    }
                                >
                                    {t('297')}
                                </Text>
                                <IconUndo
                                    w="14"
                                    h="14"
                                    fill={
                                        settings?.boxStyle
                                            ? '#828282'
                                            : `#${BackgroundText ? BackgroundText : '828282'}`
                                    }
                                />
                            </HStack>
                        </Button>
                    </Flex>
                    <Divider />

                    <Box padding="20px" paddingBottom="0">
                        <Formik initialValues={{ searchValue: searchQuery }} onSubmit={() => {}}>
                            {({ submitForm }) => (
                                <Form
                                    style={{ width: '100%', maxWidth: '468px', margin: '0 auto', position: 'relative' }}
                                >
                                    <InputGroup w="100%" maxW="full" borderRadius="8px" overflow="hidden" mx="0">
                                        <InputLeftElement pointerEvents="none">
                                            <IconSearch w="16" h="16" fill="#333333" />
                                        </InputLeftElement>
                                        {localization[10] ? (
                                            <Input
                                                placeholder={localization[10]}
                                                name={'searchValue'}
                                                value={searchQuery}
                                                onChange={(e) => handleSearchChange(e, submitForm)}
                                                autoComplete={'off'}
                                                borderRadius={'8px'}
                                            />
                                        ) : (
                                            <Input
                                                placeholder={`${t('2493')}`}
                                                name={'searchValue'}
                                                value={searchQuery}
                                                onChange={(e) => handleSearchChange(e, submitForm)}
                                                autoComplete={'off'}
                                                borderRadius={'8px'}
                                            />
                                        )}
                                    </InputGroup>
                                </Form>
                            )}
                        </Formik>
                    </Box>

                    <VStack p={{ base: 4, xl: 6 }} gap={6}>
                        <Box as="div" w="full">
                            <Accordion defaultIndex={[0, 1, 2, 3, 4]} allowMultiple>
                                {settings?.area && !isSingleEmptyCountry && (
                                    <AccordionItem border="none">
                                        <AccordionButton px="0" _hover={{ bg: 'transparent' }}>
                                            <HStack flex="1" textAlign="left">
                                                <Text
                                                    color={
                                                        settings?.boxStyle
                                                            ? 'text.main'
                                                            : `#${BackgroundText ? BackgroundText : '1A1A1A'}`
                                                    }
                                                    fontWeight="600"
                                                    fontSize={'16px'}
                                                >
                                                    {localization[13] ? localization[13] : t('2414')}
                                                </Text>
                                                <Flex
                                                    color={'#0C0C0D'}
                                                    backgroundColor={'#F5F5F5'}
                                                    fontSize={'12px'}
                                                    fontWeight={500}
                                                    borderRadius={'2px'}
                                                    px={'4px'}
                                                ></Flex>
                                            </HStack>
                                            <AccordionIcon _hover={{ color: 'blue.500' }} />
                                        </AccordionButton>
                                        <AccordionPanel pb={4} px={0}>
                                            <AreasAccordion
                                                areasData={areasData}
                                                filters={filters}
                                                onSelect={onSelect}
                                                countryParams={countryParams}
                                                settings={settings}
                                                BackgroundText={BackgroundText}
                                                regionParams={regionParams}
                                                cityParams={cityParams}
                                            />
                                        </AccordionPanel>
                                    </AccordionItem>
                                )}
                                {settings?.industry && (
                                    <AccordionItem border="none">
                                        <AccordionButton px="0" _hover={{ bg: 'transparent' }}>
                                            <HStack flex="1" textAlign="left">
                                                <Text
                                                    color={
                                                        settings?.boxStyle
                                                            ? 'text.main'
                                                            : `#${BackgroundText ? BackgroundText : '1A1A1A'}`
                                                    }
                                                    fontWeight="600"
                                                    fontSize={'16px'}
                                                >
                                                    {localization[14] ? localization[14] : t('189')}
                                                </Text>
                                                <Flex
                                                    color={'#0C0C0D'}
                                                    backgroundColor={'#F5F5F5'}
                                                    fontSize={'12px'}
                                                    fontWeight={500}
                                                    borderRadius={'2px'}
                                                    px={'4px'}
                                                >
                                                    {/*{industryTotalCount}*/}
                                                </Flex>
                                            </HStack>
                                            <AccordionIcon _hover={{ color: 'blue.500' }} />
                                        </AccordionButton>
                                        <AccordionPanel pb={4} px={0}>
                                            <Flex flexDirection="column" gap={'14px'} alignItems="flex-start">
                                                {filters?.industry
                                                    ? Object.values(filters.industry || {}).map(
                                                          (industry: PostsFilterItems[0]) => (
                                                              <HStack
                                                                  w="full"
                                                                  // justifyContent="space-between"
                                                                  key={industry.id}
                                                              >
                                                                  <CheckboxItem // TODO: Industry
                                                                      id={industry.id}
                                                                      name={industry.name}
                                                                      // selected={industry.selected}
                                                                      selected={industryParams.includes(industry.id)}
                                                                      onSelect={onSelect}
                                                                      type="industry"
                                                                      size={'lg'}
                                                                      fontSize={'14px'}
                                                                      fontWeight={'500'}
                                                                  />
                                                                  <Text
                                                                      fontSize={'14px'}
                                                                      fontWeight={'500'}
                                                                      color={
                                                                          settings?.boxStyle
                                                                              ? 'text.thirdly'
                                                                              : `#${BackgroundText ? BackgroundText : '747474'}`
                                                                      }
                                                                      pr={1.5}
                                                                  >
                                                                      ({industry.totalCount})
                                                                  </Text>
                                                              </HStack>
                                                          )
                                                      )
                                                    : null}
                                            </Flex>
                                        </AccordionPanel>
                                    </AccordionItem>
                                )}
                                {settings?.jobType && (
                                    <AccordionItem border="none">
                                        <AccordionButton px="0" _hover={{ bg: 'transparent' }}>
                                            <HStack flex="1" textAlign="left">
                                                <Text
                                                    color={
                                                        settings?.boxStyle
                                                            ? 'text.main'
                                                            : `#${BackgroundText ? BackgroundText : '1A1A1A'}`
                                                    }
                                                    fontWeight="600"
                                                    fontSize={'16px'}
                                                >
                                                    {localization[15] ? localization[15] : t('331')}
                                                </Text>
                                                <Flex
                                                    color={'#0C0C0D'}
                                                    backgroundColor={'#F5F5F5'}
                                                    fontSize={'12px'}
                                                    fontWeight={500}
                                                    borderRadius={'2px'}
                                                    px={'4px'}
                                                >
                                                    {/*{jobTypeTotalCount}*/}
                                                </Flex>
                                            </HStack>
                                            <AccordionIcon _hover={{ color: 'blue.500' }} />
                                        </AccordionButton>
                                        <AccordionPanel pb={4} px={0}>
                                            <Flex flexDirection="column" gap={'14px'} alignItems="flex-start">
                                                {filters?.jobType
                                                    ? Object.values(filters.jobType || {}).map(
                                                          (jobType: PostsFilterItems[0]) => (
                                                              <HStack
                                                                  w="full"
                                                                  // justifyContent="space-between"
                                                                  key={jobType.id}
                                                              >
                                                                  <CheckboxItem // TODO: JobType
                                                                      id={jobType.id}
                                                                      name={jobType.name}
                                                                      // selected={jobType.selected}
                                                                      selected={jobTypeParams.includes(jobType.id)}
                                                                      onSelect={onSelect}
                                                                      type="jobType"
                                                                      size={'lg'}
                                                                      fontSize={'14px'}
                                                                      fontWeight={'500'}
                                                                  />
                                                                  <Text
                                                                      fontSize={'14px'}
                                                                      fontWeight={'500'}
                                                                      color={
                                                                          settings?.boxStyle
                                                                              ? 'text.thirdly'
                                                                              : `#${BackgroundText ? BackgroundText : '747474'}`
                                                                      }
                                                                      pr={1.5}
                                                                  >
                                                                      ({jobType.totalCount})
                                                                  </Text>
                                                              </HStack>
                                                          )
                                                      )
                                                    : null}
                                            </Flex>
                                        </AccordionPanel>
                                    </AccordionItem>
                                )}
                                {settings?.office && (
                                    <AccordionItem border="none">
                                        <AccordionButton px="0" _hover={{ bg: 'transparent' }}>
                                            <HStack flex="1" textAlign="left">
                                                <Text
                                                    color={
                                                        settings?.boxStyle
                                                            ? 'text.main'
                                                            : `#${BackgroundText ? BackgroundText : '1A1A1A'}`
                                                    }
                                                    fontWeight="600"
                                                    fontSize={'16px'}
                                                >
                                                    {localization[16] ? localization[16] : t('2415')}
                                                </Text>
                                                <Flex
                                                    color={'#0C0C0D'}
                                                    backgroundColor={'#F5F5F5'}
                                                    fontSize={'12px'}
                                                    fontWeight={500}
                                                    borderRadius={'2px'}
                                                    px={'4px'}
                                                >
                                                    {/*{officeTotalCount}*/}
                                                </Flex>
                                            </HStack>
                                            <AccordionIcon _hover={{ color: 'blue.500' }} />
                                        </AccordionButton>
                                        <AccordionPanel pb={4} px={0}>
                                            <Flex flexDirection="column" gap={'14px'} alignItems="flex-start">
                                                {filters?.office
                                                    ? Object.values(filters.office || {}).map(
                                                          (office: PostsFilterItems[0]) => (
                                                              <HStack
                                                                  w="full"
                                                                  // justifyContent="space-between"
                                                                  key={office.id}
                                                              >
                                                                  <CheckboxItem // TODO: Office
                                                                      id={office.id}
                                                                      name={office.name}
                                                                      // selected={office.selected}
                                                                      selected={officeParams.includes(office.id)}
                                                                      onSelect={onSelect}
                                                                      type="office"
                                                                      size={'lg'}
                                                                      fontSize={'14px'}
                                                                      fontWeight={'500'}
                                                                  />
                                                                  <Text
                                                                      fontSize={'14px'}
                                                                      fontWeight={'500'}
                                                                      color={
                                                                          settings?.boxStyle
                                                                              ? 'text.thirdly'
                                                                              : `#${BackgroundText ? BackgroundText : '747474'}`
                                                                      }
                                                                      pr={1.5}
                                                                  >
                                                                      ({office.totalCount})
                                                                  </Text>
                                                              </HStack>
                                                          )
                                                      )
                                                    : null}
                                            </Flex>
                                        </AccordionPanel>
                                    </AccordionItem>
                                )}
                            </Accordion>
                        </Box>
                        <Show below="xl">
                            <Box
                                position="fixed"
                                bottom={0}
                                left={0}
                                right={0}
                                px={4}
                                py={2}
                                bg="bg.1"
                                w="full"
                                borderTop="1px solid"
                                borderColor="border.1"
                            >
                                <Flex w="full" justifyContent="space-between">
                                    <AppButton
                                        translate="no"
                                        title={`${meta?.filteredCount} ${t('3925')}`}
                                        variant="primary"
                                        w="167px"
                                        onClick={() => closeModal()}
                                    />
                                    <Button variant="secondary" w="167px" onClick={() => closeModal()}>
                                        {t('416')}
                                    </Button>
                                </Flex>
                            </Box>
                        </Show>
                    </VStack>
                </Box>
            </Box>
        </CustomFullScreenModal>
    )
}

export default PostSideBarNew
